export enum Status {
    COMPLETED= "COMPLETED",
    ACCEPTED= "ACCEPTED",
    UNCONFIRMED= "UNCONFIRMED",
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED"
  }


export enum PaymentStatus {
    UNPAID="UNPAID",
    BANK_TRANSFER_UNCONFIRMED="BANK_TRANSFER_UNCONFIRMED",
    BANK_TRANSFER_SENT="BANK_TRANSFER_SENT",
    BANK_TRANSFER_CONFIRMED="BANK_TRANSFER_CONFIRMED",
    CASH_TRANSFER_UNCONFIRMED="CASH_TRANSFER_UNCONFIRMED",
    CASH_TRANSFER_SENT="CASH_TRANSFER_SENT",
    CASH_TRANSFER_CONFIRMED="CASH_TRANSFER_CONFIRMED",
    PAID="PAID"
  }