import { Dispatch } from 'redux';
import { getAccounts, updatePii, updateLogin, updatePassword, deleteAccountSoft } from '@components/Account/accountService';
import { accountLoginTEST, logout } from '@utils/authUtils';
import { clearAccounts } from '@components/Account/accountSlice';
import { PII, LoginInfo, PasswordInfo } from '@/components/Account/types/Account';

export const fetchAccounts = (ids:string) => async (dispatch:Dispatch) => {
  try {
    console.log("Reached this point");
    const accounts = await getAccounts(ids);
    console.log(accounts);
    localStorage.setItem("accounts", JSON.stringify(accounts));
    return accounts;
  } catch (error) {
    if (error instanceof Error) {
      console.log("Reached an error point.");
    }
  }
};

export const updatePiInfo = async (id:string, pii:PII) => {
    await updatePii(id, pii);
}

export const updateLoginInfo = async (id:string, loginInfo:LoginInfo) => {
  await updateLogin(id, loginInfo);
  await logout(true);
}

export const updatePasswordInfo = async (id:string, passwordInfo:PasswordInfo) => {
  await updatePassword(id, passwordInfo);
}

export const deleteAccount = async () => {
  await deleteAccountSoft();
}

export const clearAccountsFromLocalStorage = () => async (dispatch:Dispatch) => {
  localStorage.removeItem("accounts");
  localStorage.removeItem("account");
  dispatch(clearAccounts());
};

export const login = async (id:string) => {
  return await accountLoginTEST(id);
}