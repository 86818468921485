// App.tsx
import React, { useState, useEffect, ChangeEvent } from "react";
import { AxiosError } from "axios";
import { getMessageContent } from "../Account/accountUtilities";
import { Message } from "../Account/types/Message";
import Severity from "../common/types/Severity";
import { formattedTime } from "@/utils/formatUtils";
import { useLocation } from "react-router-dom";
import { Client } from "./Types/Client";
import { RideResponse } from "./Types/Ride";
import { Status } from "./Types/Status";
import Button from "../common/Button";
import axiosInstance from "@/utils/axiosUtils";
import "./ShareARide.css";
import SelectValue from "../common/Select/SelectValue";
import Modal from "../Modal/Modal";

interface RideLocal {
  id?: number;
  destinationFrom: number;
  destinationTo: number;
  date: string;
  hour: string;
  minute: string;
  flightNo: string;
  passenger: PassengerLocal;
  clientId?: number;
}

interface PassengerLocal {
  id?: number;
  firstName?: string;
  lastName?: string;
  people?: number;
  luggage?: number;
  email?: string;
  rideId?: number;
}

const App: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();
  const [destination, setDestination] = useState<Location>();
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [openRides, setOpenRides] = useState<RideResponse[]>([]);
  const [selectedRide, setSelectedRide] = useState<RideResponse | null>(null);
  const [searchConfirmed, setSearchConfirmed] = useState<boolean>(false);
  const [passengerAdded, setPassengerAdded] = useState<boolean>(false);
  // TODO: Add client details
  const myParam = useLocation().search;
  const [clientName] = useState<string|null>(new URLSearchParams(myParam).get("client"));
  const [client, setClient] = useState<Client | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [newRide, setNewRide] = useState<RideLocal>({
    destinationFrom: 0,
    destinationTo: 0,
    date: "",
    hour: "00",
    minute: "00",
    flightNo: "",
    passenger: {},
  });
  const [passenger, setPassenger] = useState<PassengerLocal>({
    firstName: "",
    lastName: "",
    people: 1,
    luggage: 1,
    email: "",
  });
  const [message, setMessage] = useState<Message | null>(null);
  const [resetDestination, setResetDestination] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function fetchClient() {
      if (clientName) {
        // Fetch locations
        const response = await axiosInstance.get(`/clients/${clientName}`)
        .then((response) => {
          console.log(response.data);
          setClient(response.data);
          var clientId = response.data.id;
          setClientId(clientId);
          setNewRide({...newRide, clientId: clientId});
        })
        .catch((error: unknown)=>{
          if (error instanceof AxiosError) {
              setMessage({ message: error.message, severity: Severity.Error });
            }
        }
        );
        return response;
      }
    }
    
    fetchClient();

    var clientLocations = "";
    if (clientId) {
      setMessage(null);
      clientLocations = "/clients/"+clientId;
    
    console.log(clientId);
    console.log(clientLocations);

    // Fetch locations
      axiosInstance.get<Location[]>(`/locations`+clientLocations)
      .then((response) => {
        console.log(response.data);
        setLocations(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      }
      );
    } else {
      setMessage({ message: "Client does not exist", severity: Severity.Error });
    }
      // eslint-disable-next-line
  }, [clientName, clientId]);

  var clientPath = '';
  if (clientId) {
    clientPath = '/'+clientId;
  }

  const handleLocationOptionChange = (value: number, property: string) => {
    const locationId = value;

    if (location?.id !== locationId) {
      setResetDestination(true);
    }

    try {
      axiosInstance
        .get<Tariff[]>(`/tariffs`+clientPath+`?locationId=${locationId}`)
        .then((response) => {
          setTariffs(response.data);
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
                setMessage({ message: error.message, severity: Severity.Error });
              }
          });
      setLocation(
        locations.filter(
          (location) => location.id === Number(locationId)
        )[0]
      );
      setNewRide({
        ...newRide,
        [property]: value,
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  }

  const handleDestinationChange = (e: number) => {

    setResetDestination(false);

    var tariff = tariffs.filter(
      (tariff) => tariff.id === Number(e)
    )[0];

      setDestination(
        tariff.toLocation
      );
      setNewRide({
        ...newRide,
        "destinationTo": tariff.toLocation.id,
      });
  };

  const handlePassengerInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setPassenger({
      ...passenger,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setNewRide({
      ...newRide,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // Concatenate date, hour, and minute to form a datetime string
    const dateTime = `${newRide.date}T${newRide.hour}:${newRide.minute}`;

    // Use dateTime in your API request or other logic
    console.log("DateTime:", dateTime);

    console.log(newRide);

    // Update Axios call to include the base URL
    try {
      axiosInstance
        .get(
          `/rides${clientPath}/${newRide.destinationFrom}/${newRide.destinationTo}/${newRide.date}/${newRide.hour}:${newRide.minute}`
        )
        .then((response) => {
          console.log("Rides found:", response.data);
          setOpenRides(response.data);
          // Handle success as needed
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
                setMessage({ message: error.message, severity: Severity.Error });
              }
          });
      setSearchConfirmed(true);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  const handleSubmitNew = () => {
    // Concatenate date, hour, and minute to form a datetime string
    const dateTime = `${newRide.date}T${newRide.hour}:${newRide.minute}`;

    // Use dateTime in your API request or other logic
    console.log("DateTime:", dateTime);

    console.log(newRide);

    try {
      // Update Axios call to include the base URL
      axiosInstance
        .post(`/rides`, { ...newRide, passenger })
        .then((response) => {
          console.log("Ride created:", response.data);
          setOpenRides([...openRides, response.data]);
          // Handle success as needed
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
                setMessage({ message: error.message, severity: Severity.Error });
              }
          });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  const selectRide = (ride: RideResponse) => {
    console.log(ride);
    setSelectedRide(ride);
  };

  const renderTimeOptions = (max: number, step?: number) => {
    const options: JSX.Element[] = [];
    step = step ? step : 1;
    for (let i = 0; i <= max; i = i + step) {
      const value = i.toString().padStart(2, "0"); // Pad with leading zero if needed
      options.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };

  const renderNumberOptions = (max: number, step?: number, startFrom?: number) => {
    const options: JSX.Element[] = [];
    step = step ? step : 1;
    startFrom = startFrom ? startFrom : 0;
    for (let i = startFrom; i <= max; i = i + step) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const handleJoinSelectedRide = async () => {
    try {
      axiosInstance
        .post(`/passengers`, {
          ...passenger,
          rideId: selectedRide?.id,
        })
        .then((response) => {
          console.log("Passenger added:", response.data);
          // Handle success as needed
          setPassengerAdded(true);
        })
        .catch((error: unknown)=>{
            if (error instanceof AxiosError) {
                setMessage({ message: error.message, severity: Severity.Error });
              }
          });
      setSelectedRide(null);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      handleSubmit();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  var showFlightNo = (location != null && location.airportInd === true) || (destination != null && destination.airportInd === true);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  let clientContext;
  let clientImage;
  if (client !== null) {
    clientContext = <div style={{textAlign: "center"}}><label>Client:</label> <b>{client.companyName}</b></div>;

    if (client.uniqueIdentifier === "happyrabbit") {
      clientImage = <div style={{textAlign: "center"}}><img src={require('@common/happyrabbit/happyrabbit.png')} style={{margin: "5px", borderRadius: "15px"}} width="160" height="120" alt={client.uniqueIdentifier}/></div>;
    }

  } else {
    return (<div>
      <div>Currently this functionality is only available for specific clients. Please use a client's unique link to access application.</div>
      <a href={`/share-a-ride?client=happyrabbit`}>Happy Rabbit Transfer Services</a>
    </div>);
  }

  var locationOptions = locations.map((location)=>(
    {value: location.id,
    label: location.location_name}));

    var tariffOptions = tariffs.map((tariff)=>(
      {value: tariff.id,
      label: tariff.toLocation.location_name}));

  return (
    <div>
      {messageContent}
      
      <div className="div-container">
      <div className="div-content">
      <div>
      <div style={{textAlign: "center"}}><h1>Book a trip</h1></div>
      {clientContext}
        {clientImage}
      <div>
        <label className="bookatrip"><b>PICK-UP LOCATION</b></label>
        <SelectValue options={locationOptions} onSelectChange={(e) => handleLocationOptionChange(e, "destinationFrom")} />
      </div>
      <div>
        <label className="bookatrip"><b>DROP-OFF LOCATION</b></label>
        <SelectValue options={tariffOptions} onSelectChange={(e) => handleDestinationChange(e)} resetValue={resetDestination}/>
      </div>
      {showFlightNo && (
        <div >
          <label className="bookatrip">Flight Number</label>
          <input style={{width: "100%"}}
            type="text"
            name="flightNo"
            placeholder="No spaces e.g.A3600"
            onChange={handleInputChange}
          />
        </div>
      )}
      <div>
        <label className="bookatrip">Date</label>
        <input type="date" name="date" onChange={handleInputChange} />
      </div>
      <div>
        <label className="bookatrip">Time</label>
        <select name="hour" value={newRide.hour} onChange={handleInputChange}>
          {renderTimeOptions(23)}
        </select>
        :
        <select
          name="minute"
          value={newRide.minute}
          onChange={handleInputChange}
        >
          {renderTimeOptions(59, 5)}
        </select>
      </div>
      <Button style={{width: "100%", marginTop: "15px"}} onClick={handleSubmit}>SEARCH FOR A RIDE</Button>
      </div>
      {/* Display the created rides in a list */}
      {openRides.length > 0 && (
        <div>
          <h2>Rides to share found:</h2>
          <ul>
            {openRides.map((ride) => (
              <li key={ride.id}>
                {selectedRide != null && ride.id === selectedRide.id && (
                  <b>[Selected]</b>
                )}
                {`${ride.companyName? ride.companyName+", ": ""}${ride.destinationFrom.location_name} to ${
                  ride.destinationTo.location_name
                } on ${ride.date} at ${formattedTime(ride.hour, ride.minute)}${
                  ride.flightNo !== null && ride.flightNo !== ""
                    ? `. Flight No: ${ride.flightNo}`
                    : ""
                } (Bookings: ${ride.bookingCount}, Passengers: ${
                  ride.passengerCount
                }/${ride.passengerTotal}, Luggage: ${ride.luggageCount}/${
                  ride.luggageTotal
                })`}
                {ride.status === Status.UNCONFIRMED ? <b> - Not yet confirmed </b> :
                <Button onClick={() => selectRide(ride)}>Join</Button>
            }
              </li>
            ))}
          </ul>
        </div>
      )}
      {searchConfirmed && openRides.length === 0 && (
        <div>
          No existing rides at this date and time. You can start a new booking
          below.
        </div>
      )}

      {searchConfirmed && (
        <div>
          <h2>Passenger Info</h2>
          <div>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={handlePassengerInputChange}
            />
          </div>
          <div>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={handlePassengerInputChange}
            />
          </div>
          <div>
            <label>Number of People:</label>
            <select
              name="people"
              value={passenger.people}
              onChange={handlePassengerInputChange}
            >
              {renderNumberOptions(6,1,1)}
            </select>
          </div>
          <div>
            <label>Luggage:</label>
            <select
              name="luggage"
              value={passenger.luggage}
              onChange={handlePassengerInputChange}
            >
              {renderNumberOptions(6)}
            </select>
          </div>
          <div>
            <label>Email:</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={handlePassengerInputChange}
            />
          </div>
        </div>
      )}

      {selectedRide != null && (
        <div>
          <h2>Join the selected ride?</h2>
          <Button onClick={handleJoinSelectedRide}>Yes</Button>
          <Button
            onClick={() => {
              setSelectedRide(null);
              setPassengerAdded(false);
            }}
          >
            No
          </Button>
        </div>
      )}

      {passengerAdded && <p>Good news, you are in! Confirm your email and proceed to payment within 48 hours to complete your booking!</p>}

      {searchConfirmed && <Button onClick={handleSubmitNew}>Create New</Button>}

      
    </div>
    </div>
    <button onClick={openModal}>Open Modal</button>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <h2>This is the modal content</h2>
        <p>You can put any content here.</p>
      </Modal>
    </div>
    
  );
};

export default App;
