const NoMatch = () => {
  return (
    <div>
      <h2>404: Page Not Found</h2>
      <p>This url does not match to anything.</p>
    </div>
  );
};

export default NoMatch;
