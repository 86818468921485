enum EuropeanCountry {
    ALBANIA = 'Albania',
    ANDORRA = 'Andorra',
    AUSTRIA = 'Austria',
    BELARUS = 'Belarus',
    BELGIUM = 'Belgium',
    BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
    BULGARIA = 'Bulgaria',
    CROATIA = 'Croatia',
    CYPRUS = 'Cyprus',
    CZECH_REPUBLIC = 'Czech Republic',
    DENMARK = 'Denmark',
    ESTONIA = 'Estonia',
    FINLAND = 'Finland',
    FRANCE = 'France',
    GERMANY = 'Germany',
    GREECE = 'Greece',
    HUNGARY = 'Hungary',
    ICELAND = 'Iceland',
    IRELAND = 'Ireland',
    ITALY = 'Italy',
    KOSOVO = 'Kosovo',
    LATVIA = 'Latvia',
    LIECHTENSTEIN = 'Liechtenstein',
    LITHUANIA = 'Lithuania',
    LUXEMBOURG = 'Luxembourg',
    MALTA = 'Malta',
    MOLDOVA = 'Moldova',
    MONACO = 'Monaco',
    MONTENEGRO = 'Montenegro',
    NETHERLANDS = 'Netherlands',
    NORTH_MACEDONIA = 'North Macedonia',
    NORWAY = 'Norway',
    POLAND = 'Poland',
    PORTUGAL = 'Portugal',
    ROMANIA = 'Romania',
    RUSSIA = 'Russia',
    SAN_MARINO = 'San Marino',
    SERBIA = 'Serbia',
    SLOVAKIA = 'Slovakia',
    SLOVENIA = 'Slovenia',
    SPAIN = 'Spain',
    SWEDEN = 'Sweden',
    SWITZERLAND = 'Switzerland',
    UKRAINE = 'Ukraine',
    UNITED_KINGDOM = 'United Kingdom',
    VATICAN_CITY = 'Vatican City',
  }
  
  export default EuropeanCountry;