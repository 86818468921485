import Select from 'react-select';
import './SelectEnum.css';

// const colourStyles = {
//   control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
//   option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
//     const color = chroma(data.color);
//     return {
//       ...styles,
//       backgroundColor: isDisabled ? 'red' : 'blue',
//       color: '#FFF',
//       cursor: isDisabled ? 'not-allowed' : 'default',
//     };
//   },
// };

interface SelectProps<T> {
  selectedValue: string | undefined;
  enumObject: Record<string, T>;
  onSelect: (value: string) => void;
  viewOnly?: boolean
}

export const getKeyFromValue = <T extends Record<string, string>>(
  enumToSearch: T,
  value: string
): keyof T => {
  return Object.keys(enumToSearch).find((key) => enumToSearch[key] === value) as keyof T;
};

  export const getEnumKeys = <T extends Record<string, string | number>>(
    enumToDeconstruct: T
  ): Array<keyof typeof enumToDeconstruct> => {
    return Object.keys(enumToDeconstruct) as Array<keyof typeof enumToDeconstruct>;
  };

const SelectEnum = <T extends string>({selectedValue, enumObject, onSelect, viewOnly}: SelectProps<T>) => {
  const options = Object.values(enumObject).map((value) => ({
    value: getKeyFromValue(enumObject, value),
    label: value,
  }));

  const selectedOption = options.find((option) => option.value === selectedValue);

  const onSelectEnum = (selected: string) => {
    onSelect(selected);
  }

  return (
    <Select
      value={selectedOption || { value: enumObject[Object.keys(enumObject)[0]].toString(), label: enumObject[Object.keys(enumObject)[0]].toString() }}
      onChange={(selectedOption) => (selectedOption?.label != null && onSelectEnum(selectedOption.label))}
      options={options}
      placeholder="Select Option"
      className='react-select-container'
      classNamePrefix="react-select"
      isDisabled = {viewOnly}
    />
  );
};

export default SelectEnum;
