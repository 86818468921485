import {useState } from "react";
import {updatePiInfo} from "@store/actions/accountActions";
import { AxiosError } from "axios";
import { Account } from "./types/Account";
import Severity from "../common/types/Severity";
import Button from "@common/Button";

interface IMyProps {
  account: Account,
  infoUpdateHandler: Function,
  messageHandler: Function
}

const PII: React.FC<IMyProps> = (props:IMyProps) => {
  const account = props.account;
  const [firstName, setFirstName] = useState(
    (account !== null && account !== undefined) ? account.firstName : ""
  );
  const [lastName, setLastName] = useState(
    (account !== null && account !== undefined) ? account.lastName : ""
  );

  const success = () => {
    localStorage.setItem("account", JSON.stringify(account));
    props.infoUpdateHandler(true);
    props.messageHandler({message: "Personal identifiable information updated", severity: Severity.Info});
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (account !== null) {
      if (firstName !== account.firstName || lastName !== account.lastName) {
        try {
          await updatePiInfo(account.id, { firstName: firstName, lastName: lastName });
          success();
        } catch (error: unknown) {
          if (error instanceof AxiosError) {
            props.messageHandler({message: error.message, severity: Severity.Error});
          }
        }
      } else {
        props.messageHandler({message: "Nothing has changed here", severity: Severity.Warning});
      }
    } else {
      props.messageHandler({message: "There is no logged in user available", severity: Severity.Warning});
    }
  };

  return (
    <div>
      <h2>Personal Identifiable Information (PII)</h2>
      <form onSubmit={handleSubmit}>
        <label>First name: </label>
        <input
          type="text"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
            props.infoUpdateHandler(false);
          }}
        />
        <label>Last name: </label>
        <input
          type="text"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
            props.infoUpdateHandler(false);
          }}
        />
        <Button type="submit" style= {{display: "block"}}>Update</Button>
      </form>
    </div>
  );
};

export default PII;
