import {useState} from 'react'
import AddressList from './AddressList'
import { useAppDispatch, useAppSelector } from '@store/hooks' 
import {clearContactsFromLocalStorage } from '@store/actions/addressActions'
import {fetchAddressesAsync, selectContacts, selectContactsTimestamp } from './addressSlice';
import Scope from './types/Scope';
import AddressForm from './AddressForm';
import "./AddressPage.css";
import SelectEnum, { getKeyFromValue } from '@common/Select/SelectEnum';
import AddressDetails from './types/AddressDetails';
import { formattedTimestamp } from '@utils/formatUtils';
import Button from "@common/Button";

const Contacts = () => {
    const addresses = useAppSelector(selectContacts);
    const timestamp = useAppSelector(selectContactsTimestamp);
    const [scope, setScope] = useState(getKeyFromValue(Scope, Scope.CONTACT));
    const [selectedAddress, setSelectedAddress] = useState<AddressDetails|undefined>(undefined);
    const [showNewForm, setShowNewForm] = useState(false);
    const dispatch = useAppDispatch();

    const handleSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      dispatch(fetchAddressesAsync(scope));
      setShowNewForm(false);
    };

    const toggleNewForm = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (selectedAddress) setSelectedAddress(undefined);
      setShowNewForm(!showNewForm);
    }

    const handleClear = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      clearContacts();
    };

    const clearContacts = () => {
      dispatch(clearContactsFromLocalStorage());
    };

    const handleScopeSelect = (scope: string) => {
      var scopeKey = getKeyFromValue(Scope, scope);
      setScope(scopeKey);
      dispatch(fetchAddressesAsync(scopeKey));
    };

    const handleAddressSelected = (addressDetails: AddressDetails) => {
      setSelectedAddress(addressDetails);
      setShowNewForm(true);
    }

return (
  <div>
    <h1>Contacts</h1>
    {!showNewForm && <div className="form-inline"><form onSubmit={handleSubmit} className="form-inline">
    <SelectEnum selectedValue={scope} enumObject={Scope} onSelect={handleScopeSelect} viewOnly/>
    <span>{formattedTimestamp(timestamp)}</span>
      <Button type="submit">Refresh</Button>
    </form>
    <form onSubmit={handleClear} className="form-inline">
      <Button type="submit">Clear</Button>
    </form></div>}
    <form onSubmit={toggleNewForm} className="form-inline">
      <Button type={`${showNewForm? 'warning': 'confirm'}`}>{showNewForm? "Back" : "+"}</Button>
    </form>
    <div className="app-container">
      {!showNewForm && <div className="address-list">
        <AddressList addresses={addresses} handleAddressSelect = {handleAddressSelected}/>
      </div>}
      {showNewForm && <div className="address-form">
        <AddressForm selectedAddress = {selectedAddress} scope = {scope} viewOnly = {true} setSelectedAddress={setSelectedAddress}/>
      </div>}
    </div>
    
  </div>
)
}

export default Contacts;