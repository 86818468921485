import LandingPage from '../LandingPage/LandingPage';

const Dashboard = () => {

  return (
  <div>
    <LandingPage/>
  </div>
  );
}

export default Dashboard;