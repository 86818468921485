import React from 'react';
import AccountDetail from './AccountDetail';
import { Account } from './types/Account';

interface IMyProps {
  accounts: Account[],
}

const AccountList: React.FC<IMyProps> = (props:IMyProps) => {
  return (<div>
    {props.accounts.map((account: Account) => (
      <AccountDetail key={account.id} accountDetails={account} />
    ))}
  </div>)
}

export default AccountList;