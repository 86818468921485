import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Navbar from "@components/Navigation/Navbar";

const App = () => {
  return (
    <Router>
        <Navbar/>
    </Router>
  );
};

export default App;
