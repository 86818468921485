import React, { useState } from 'react';
import { RiCloseLine } from "react-icons/ri";
import './Modal.css';

export enum ButtonType {
  primary="primaryBtn",
  delete="deleteBtn",
  cancel="cancelBtn"
}

export interface Action {
  name: string,
  method: Function,
  buttonType: ButtonType
}

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: any;
  actions?: Action[];
  cancelAction?: Function;
  deleteAction?: Function;
}

const Modal: React.FC<ModalProps> = ({ title, isOpen, onClose, children,actions, cancelAction, deleteAction}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const closeModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      onClose();
    }, 300); // Adjust the time as needed to match your CSS animation duration
  };

  if (!isOpen && !isAnimating) return null;

  return (
    <>
      <div className="darkBG" onClick={closeModal} />
      <div className="centered">
        <div className="modalH">
          <div className="modalHeader">
            <h5 className="heading">{title}</h5>
          </div>
          <button className={"closeBtn"} onClick={closeModal}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className="modalContent">
            {children}
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
            {actions?.map((action) => (
                <button
                key={action.name}
                className={action.buttonType}
                onClick={() => action.method()}>
                {action.name}
              </button>
              ))}
              {deleteAction && <button className={ButtonType.delete} onClick={() => deleteAction}>
                Delete
              </button>}
              <button
                className={ButtonType.cancel}
                onClick={cancelAction? ()=>cancelAction : closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
