import React from "react";
import "./Detail.css";
import { Location } from "../../Types/Tariff";

interface IMyProps {
  details: Location,
  handleSelect: Function,
}

const Detail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleSelect(props.details);
  }

  return (
    <li key={props.details.id} onClick={handleSelect} className={`list-item`}>
      <div><p><b>{props.details.id}</b></p></div>
      <div><p><b>, {props.details.location_name}</b> {props.details.airportInd && <span> (Airport)</span>}</p></div>
    </li>);
};

export default Detail;