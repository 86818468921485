import {useState} from "react";
import {updatePasswordInfo } from "@store/actions/accountActions";
import { AxiosError } from "axios";
import { Account } from "./types/Account";
import Button from "@common/Button";
import Severity from "../common/types/Severity";

interface IMyProps {
  account: Account,
  infoUpdateHandler: Function,
  messageHandler: Function
}

const Password: React.FC<IMyProps> = (props:IMyProps) => {
  const account = props.account;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const success = () => {
    localStorage.setItem("account", JSON.stringify(account));
    props.infoUpdateHandler(true);
    props.messageHandler({message: "Password updated", severity: Severity.Info});
  }

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (account !== null) {
      if (newPassword !== oldPassword ) {
        if (newPassword === confirmNewPassword) {
          try {
            await updatePasswordInfo(account.id, { oldPassword: oldPassword, password: newPassword});
            success();
          } catch (error) {
            if (error instanceof AxiosError) {
              props.messageHandler({message: error.message, severity: Severity.Error});
            } 
          }
          
        } else {
          props.messageHandler({message: "Confirm password incorrect", severity: Severity.Warning});
        }
      } else {
        props.messageHandler({message: "Passwords are the same", severity: Severity.Warning});
      }
    } else {
      props.messageHandler({message: "There is no logged in user available", severity: Severity.Warning});
    }
  };

  return (
    <div>
      <h2>Password</h2>
      <form onSubmit={handleSubmit}>
        <label>Old Password: </label>
        <input
          placeholder="*****"
          type="password"
          value={oldPassword}
          onChange={(event) => {setOldPassword(event.target.value); props.infoUpdateHandler(false);}}
        />
        <label>New Password: </label>
        <input
          placeholder="*****"
          type="password"
          value={newPassword}
          onChange={(event) => {setNewPassword(event.target.value); props.infoUpdateHandler(false);}}
        />
        <label>Confirm New Password: </label>
        <input
          placeholder="*****"
          type="password"
          value={confirmNewPassword}
          onChange={(event) => {setConfirmNewPassword(event.target.value); props.infoUpdateHandler(false);}}
        />
        <Button type="submit" style= {{display: "block"}}>Update</Button>
      </form>
    </div>
  );
};

export default Password;
