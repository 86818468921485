import React, { useState } from "react";
import DetailsStatus from "./types/DetailsStatus";
import DetailsCategory from "./types/DetailsCategory";
import DetailsType from "./types/DetailsType";
import Scope from "./types/Scope";
import AddressDetails from "./types/AddressDetails";
import EuropeanCountry from "../common/types/EuropeanCountry";
import { addAddress, deleteAddress, patchAddress } from "./addressService";
import { AxiosError } from "axios";
import { getMessageContent } from "../Account/accountUtilities";
import { Message } from "../Account/types/Message";
import Severity from "../common/types/Severity";
import SelectEnum, { getKeyFromValue } from "../common/Select/SelectEnum";
import "./AddressForm.css";
import DeleteAddress from "./DeleteAddress";
import { useAppDispatch } from "@/store/hooks";
import { fetchAddressesAsync } from "./addressSlice";
import Button from "@common/Button";

interface IMyProps {
  selectedAddress?: AddressDetails;
  scope?: string;
  viewOnly?: boolean;
  setSelectedAddress?: Function;
}

const AddressForm: React.FC<IMyProps> = (props: IMyProps) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<Message | null>(null);
  const [deleted, setDeleted] = useState(false);
  const [formData, setFormData] = useState<AddressDetails>({
    id: props.selectedAddress ? props.selectedAddress.id : null,
    accountId:
      props.selectedAddress && props.selectedAddress.accountId
        ? props.selectedAddress.accountId
        : null,
    name:
      props.selectedAddress && props.selectedAddress.name
        ? props.selectedAddress.name
        : "",
    phoneDescription:
      props.selectedAddress && props.selectedAddress.phoneDescription
        ? props.selectedAddress.phoneDescription
        : "",
    phoneNumber:
      props.selectedAddress && props.selectedAddress.phoneNumber
        ? props.selectedAddress.phoneNumber
        : "",
    emailDescription:
      props.selectedAddress && props.selectedAddress.emailDescription
        ? props.selectedAddress.emailDescription
        : "",
    email:
      props.selectedAddress && props.selectedAddress.email
        ? props.selectedAddress.email
        : "",
    status:
      props.selectedAddress && props.selectedAddress.status
        ? props.selectedAddress.status
        : getKeyFromValue(DetailsStatus, DetailsStatus.CURRENT_DEFAULT),
    addressDescription:
      props.selectedAddress && props.selectedAddress.addressDescription
        ? props.selectedAddress.addressDescription
        : "",
    category:
      props.selectedAddress && props.selectedAddress.category
        ? props.selectedAddress.category
        : getKeyFromValue(DetailsCategory, DetailsCategory.HOME),
    type:
      props.selectedAddress && props.selectedAddress.type
        ? props.selectedAddress.type
        : getKeyFromValue(DetailsType, DetailsType.ADDRESS),
    addressLine1:
      props.selectedAddress && props.selectedAddress.addressLine1
        ? props.selectedAddress.addressLine1
        : "",
    addressLine2:
      props.selectedAddress && props.selectedAddress.addressLine2
        ? props.selectedAddress.addressLine2
        : "",
    townOrCity:
      props.selectedAddress && props.selectedAddress.townOrCity
        ? props.selectedAddress.townOrCity
        : "",
    county:
      props.selectedAddress && props.selectedAddress.county
        ? props.selectedAddress.county
        : "",
    postcode:
      props.selectedAddress && props.selectedAddress.postcode
        ? props.selectedAddress.postcode
        : "",
    country:
      props.selectedAddress && props.selectedAddress.country
        ? props.selectedAddress.country
        : getKeyFromValue(EuropeanCountry, EuropeanCountry.UNITED_KINGDOM),
    scope: props.selectedAddress
      ? props.selectedAddress.scope
      : getKeyFromValue(Scope, Scope.PERSONAL),
  });
  const [viewOnly, setViewOnly] = useState(props.selectedAddress ? props.viewOnly : false);

  if (props.scope && props.scope !== formData.scope) {
    setFormData({ ...formData, scope: props.scope });

    console.log(formData.scope);
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange =
    (enumObject: Record<string, string>, name: keyof AddressDetails) =>
    (selectedOption: any) => {
      setFormData((prevData) => ({
        ...prevData,
        [name]: getKeyFromValue(enumObject, selectedOption),
      }));
    };

  const toggleView = (event: React.FormEvent) => {
    event.preventDefault();
    setViewOnly(!viewOnly);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (!viewOnly) {
        if (props.selectedAddress) {
          await patchAddress(formData);
        } else {
          await addAddress(formData);
        }
        if (props.setSelectedAddress) {
          props.setSelectedAddress(formData);
        }
      }
      dispatch(fetchAddressesAsync(formData.scope));
      setMessage({ message: "SUCCESS", severity: Severity.Info });
      setViewOnly(true);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (props.selectedAddress && props.selectedAddress.id) {
        await deleteAddress(props.selectedAddress.id);
        setMessage({
          message: "SUCCESSFULLY DELETED",
          severity: Severity.Info,
        });
        setDeleted(true);
        dispatch(fetchAddressesAsync(formData.scope));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const deleteUpdateHandler = (deleted: boolean) => {
    if (deleted) {
      handleDelete();
    }
  }

  const messageHandler = (message: Message) => {
    setMessage(message);
  }

  if (deleted) {
    return(<div>
      {messageContent}
      </div>);
  }

  return (
    <div>
      {messageContent}
      <form onSubmit={toggleView} className="form-inline">
        <Button type="warning">{viewOnly ? "Edit" : "Cancel"}</Button>
      </form>
      {props.selectedAddress && (
        <DeleteAddress
        address={props.selectedAddress} 
        infoUpdateHandler={deleteUpdateHandler} 
        messageHandler={messageHandler}/>
      )}
      <form onSubmit={handleSubmit} className="form-inline">
      {!viewOnly && <Button type="submit">
            {props.selectedAddress ? "Update" : "Submit"}
          </Button>}
        <fieldset disabled={viewOnly}>
          <div>
          {props.selectedAddress && viewOnly && 
            <h1>
                {formData.name}
            </h1>}
            {!props.selectedAddress &&  
            <h1>
                Add a new entry of Address Details
            </h1>}
            {!(viewOnly) && 
            <div>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
              />
            </div>}
          </div>
          {!(formData.phoneDescription === '' && viewOnly) && <div>
            <label>Phone Description:</label>
            <input
              type="text"
              name="phoneDescription"
              value={formData.phoneDescription}
              onChange={handleInputChange}
              placeholder="Phone Description"
            />
          </div>}
          {!(formData.phoneNumber === '' && viewOnly) && <div>
            <label>Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone Number"
            />
          </div>}
          {!(formData.emailDescription === '' && viewOnly) &&  <div>
            <label>Email Description:</label>
            <input
              type="text"
              name="emailDescription"
              value={formData.emailDescription}
              onChange={handleInputChange}
              placeholder="Email Description"
            />
          </div>}
          {!(formData.email === '' && viewOnly) && <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />
          </div>}
          <div>
            <label>Status:</label>
            <SelectEnum
              viewOnly={viewOnly}
              selectedValue={formData.status}
              enumObject={DetailsStatus}
              onSelect={handleSelectChange(DetailsStatus, "status")}
            />
          </div>
          {!(formData.addressDescription === '' && viewOnly) && <div>
            <label>Address Description:</label>
            <input
              type="text"
              name="addressDescription"
              value={formData.addressDescription}
              onChange={handleInputChange}
              placeholder="Address Description"
            />
          </div>}
          <div>
            <label>Category:</label>
            <SelectEnum
              viewOnly={viewOnly}
              selectedValue={formData.category}
              enumObject={DetailsCategory}
              onSelect={handleSelectChange(DetailsCategory, "category")}
            />
          </div>
          <div>
            <label>Type:</label>
            <SelectEnum
              viewOnly={viewOnly}
              selectedValue={formData.type}
              enumObject={DetailsType}
              onSelect={handleSelectChange(DetailsType, "type")}
            />
          </div>
          {!(formData.addressLine1 === '' && viewOnly) && <div>
            <label>Address Line 1:</label>
            <input
              type="text"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleInputChange}
              placeholder="Address Line 1"
            />
          </div>}
          {!(formData.addressLine2 === '' && viewOnly) && <div>
            <label>Address Line 2:</label>
            <input
              type="text"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleInputChange}
              placeholder="Address Line 2"
            />
          </div>}
          {!(formData.townOrCity === '' && viewOnly) && <div>
            <label>Town or City:</label>
            <input
              type="text"
              name="townOrCity"
              value={formData.townOrCity}
              onChange={handleInputChange}
              placeholder="Town or City"
            />
          </div>}
          {!(formData.county === '' && viewOnly) && <div>
            <label>County:</label>
            <input
              type="text"
              name="county"
              value={formData.county}
              onChange={handleInputChange}
              placeholder="County"
            />
          </div>}
          {!(formData.postcode === '' && viewOnly) && <div>
            <label>Postcode:</label>
            <input
              type="text"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              placeholder="Postcode"
            />
          </div>}
          <div>
            <label>Country:</label>
            <SelectEnum
              viewOnly={viewOnly}
              selectedValue={formData.country}
              enumObject={EuropeanCountry}
              onSelect={handleSelectChange(EuropeanCountry, "country")}
            />
          </div>
          <div>
            <label>Scope:</label>
            <SelectEnum
              viewOnly={viewOnly}
              selectedValue={formData.scope}
              enumObject={Scope}
              onSelect={handleSelectChange(Scope, "scope")}
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default AddressForm;
