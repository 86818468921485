import {useState} from "react";
import {updateLoginInfo } from "@store/actions/accountActions";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { Account } from "./types/Account";
import { useAppDispatch } from "@/store/hooks";
import { logout } from "@/utils/authUtils";
import { clearAccounts } from "./accountSlice";
import { clearAddresses } from "../Address/addressSlice";
import Button from "@common/Button";

interface IMyProps {
  account: Account,
  infoUpdateHandler: Function,
  messageHandler: Function
}

const LoginInfo: React.FC<IMyProps> = (props:IMyProps) => {
  const account = props.account;
  const [username, setUsername] = useState((account !== null && account !== undefined)? account.username : "");
  const [email, setEmail] = useState((account !== null && account !== undefined)? account.email: "");
  const [confirm, setConfirm] = useState(false);
  const dispatch = useAppDispatch();

  const success = () => {
    localStorage.setItem("account", JSON.stringify(account));
    props.infoUpdateHandler(true);
    props.messageHandler({message: "Login information updated. Please login again.", severity: Severity.Info});
    setConfirm(false);
    dispatch(clearAccounts());
    dispatch(clearAddresses());
    logout(true);
  }

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (account !== null) {
      if (username !== account.username || email !== account.email) {
        if (confirm) {
          try {
            await updateLoginInfo(account.id, { username: username, email: email });
            success();
            return true;
          } catch (error: unknown) {
            if (error instanceof AxiosError) {
              props.messageHandler({message: error.message, severity: Severity.Error});
            }
          }
        } else {
          props.messageHandler({message: "You are about to change login information. This action will require logging you out. Press 'Confirm' if you wish to continue", severity: Severity.Warning});
          setConfirm(true);
        }
      } else {
        props.messageHandler({message: "Nothing has changed here", severity: Severity.Warning});
      }
    } else {
      props.messageHandler({message: "There is no logged in user available", severity: Severity.Warning});
    }
  };

  return (
    <div>
      <h2>Login Info</h2>
      <form onSubmit={handleSubmit}>
        <label>Username: </label>
        <input
          type="text"
          value={username}
          onChange={(event) => {setUsername(event.target.value); props.infoUpdateHandler(false);}}
        />
        <label>Email: </label>
        <input
          type="text"
          value={email}
          onChange={(event) => {setEmail(event.target.value); props.infoUpdateHandler(false);}}
        />
        <Button type="submit" style= {{display: "block"}}>{confirm ? "Confirm" : "Update"}</Button>
      </form>
    </div>
  );
};

export default LoginInfo;
