import {combineReducers } from 'redux';
import { ThunkAction, configureStore, Action, PreloadedState} from '@reduxjs/toolkit';
import addressReducer from '@components/Address/addressSlice';
import accountReducer from '@components/Account/accountSlice';

export const rootReducer = combineReducers({
  addresses: addressReducer,
  accounts: accountReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;