import React from "react";
import "./Contact.css";
import { Client } from "../../Types/Client";

interface IMyProps {
    client: Client
}

const Contact: React.FC<IMyProps> = (props: IMyProps) => {

    let clientContext = <div style={{textAlign: "center"}}>
        <label>Client:</label> <b>{props.client.companyName}</b>
    </div>;
  
  let clientImage = null;
    try {
    let module = require(`@common/${props.client.uniqueIdentifier}/${props.client.uniqueIdentifier}.png`);
    clientImage = <div style={{textAlign: "center"}}>
        <img src={module} style={{margin: "5px", borderRadius: "15px"}} width="200" height="60" alt={props.client.uniqueIdentifier}/>
    </div>;
    } catch (error) {
    console.warn("Module not found:", error);
    }
      
  

  return (
      <div>
        <h1 style={{textAlign: "center"}}>Contact Details Card</h1>
            {clientContext}
            {clientImage}
    </div>
  );
}

export default Contact;