import React from 'react';
import RideDetail from './RideDetail';
import './RideList.css';
import { Period, Ride } from '../Types/Ride';

interface IMyProps {
  rides: Ride[] | undefined,
  handleRideSelect: Function,
  period?:Period
}

const RideList: React.FC<IMyProps> = (props:IMyProps) => {
  return (
  <div>
    <ul className='list-container'>
    {props.rides?.map((ride:Ride) => (
      <RideDetail key={ride.id} rideDetails={ride} handleRideSelect= {props.handleRideSelect} period={props.period}/>
    ))}
    </ul>
  </div>
)};

export default RideList;