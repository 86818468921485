import { useEffect, useState } from "react";
import Modal, { Action, ButtonType } from "@components/Modal/Modal";
import RideList from "@components/ShareARide/Ride/RideList";
import { ClientResponse, Period, Ride } from "@components/ShareARide/Types/Ride";
import { AxiosError } from "axios";
import RideItem from "@components/ShareARide/Ride/RideItem";
import Button from "@common/Button";
import "./ClientBookings.css";
import { useLocation } from "react-router-dom";
import { getWithAuthHeader, patchWithAuthHeader, postWithAuthHeader } from "@/utils/apiUtils";

const APPEND_URL = "/client/rides";

const ClientBookings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ridesToday, setRidesToday] = useState<Ride[]>();
  const [ridesTomorrow, setRidesTomorrow] = useState<Ride[]>();
  const [ridesFuture, setRidesFuture] = useState<Ride[]>();
  const [ridesYesterday, setRidesYesterday] = useState<Ride[]>();
  const [ridesPast, setRidesPast] = useState<Ride[]>();
  const [ride, setRide] = useState<Ride>();
  const [ridePeriod, setRidePeriod] = useState<Period>(Period.today);
  const [clientResponse, setClientResponse] = useState<ClientResponse>();
  const myParam = useLocation().search;
  const [id] = useState<string|null>(new URLSearchParams(myParam).get("id"));

  useEffect(() => {
    async function fetchClient(period: Period) {
      return await handleSearch(period);
    }

    fetchClient(Period.today);
    fetchClient(Period.tomorrow);
    fetchClient(Period.future);

    if (id) {
      getWithAuthHeader(`${APPEND_URL}/ride/${id}`)
      .then((response) => {
        console.log(response.data);
        handleRideSelect(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            // setMessage({ message: error.message, severity: Severity.Error });
          }
      }
      );
    }
// eslint-disable-next-line
  }, [id]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setRide(undefined);
    setIsOpen(false);
  };

  const handleRideSelect = (ride: Ride, period?: Period) => {
    setRide(ride);

    if (period) {
      setRidePeriod(period);
      console.log(period);
    }

    openModal();
  }

  async function handleSearch(period: Period){
    console.log(period);
    const response = await getWithAuthHeader(`${APPEND_URL}/${period}`)
    .then((response) => {
      console.log(response.data);
      if  (period === Period.today) {
        setRidesToday(response.data);
      } else if (period === Period.future) {
        setRidesFuture(response.data);
      } else if (period === Period.past) {
        setRidesPast(response.data);
      } else if (period === Period.tomorrow) {
        setRidesTomorrow(response.data);
      } else if (period === Period.yesterday) {
        setRidesYesterday(response.data);
      }
    })
    .catch((error: unknown)=>{
      if (error instanceof AxiosError) {
          // setMessage({ message: error.message, severity: Severity.Error });
        }
    }
    );
    return response;
  }


  const handleComplete = async () => {
    if (ride) {
      await patchWithAuthHeader(`${APPEND_URL}/complete/${ride.id}`, {})
      .then((response) => {
        console.log(response.data);
        setRide(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            // setMessage({ message: error.message, severity: Severity.Error });
          }
      }
      );
      handleSearch(ridePeriod);
    }
  }

  const completeAction: Action = {
    name: "Complete",
    method: handleComplete,
    buttonType: ButtonType.primary
  }

  const handleAccept = async () => {
    if (ride) {
      await postWithAuthHeader(`${APPEND_URL}/accept`, clientResponse)
      .then((response) => {
        console.log(response.data);
        setRide(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            // setMessage({ message: error.message, severity: Severity.Error });
          }
      }
      );
      handleSearch(ridePeriod);
    }
  }

  const acceptAction: Action = {
    name: "Accept",
    method: handleAccept,
    buttonType: ButtonType.primary
  }

  const handleReject = async () => {
    if (ride) {
      await patchWithAuthHeader(`${APPEND_URL}/reject/${ride.id}`, {})
      .then((response) => {
        console.log(response.data);
        setRide(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            // setMessage({ message: error.message, severity: Severity.Error });
          }
      }
      );
      handleSearch(ridePeriod);
    }
  }

  const rejectAction: Action = {
    name: "Reject",
    method: handleReject,
    buttonType: ButtonType.delete
  }

  

  return (
  <div>
    <div>
      <h1 className="text-3xl font-bold">Today's Rides {(ridesToday && ridesToday?.length > 0) && "- "+ ridesToday[0].date}</h1>
      <Button onClick={()=> handleSearch(Period.today)}>Refresh</Button>
      <RideList handleRideSelect={handleRideSelect} rides={ridesToday} period={Period.today}/>
    </div>
    <div>
      <h1 className="text-3xl font-bold">Tomorrow's Rides {(ridesTomorrow && ridesTomorrow?.length > 0) && "- "+ ridesTomorrow[0].date}</h1>
      <Button onClick={()=> handleSearch(Period.tomorrow)}>Search</Button>
      <RideList handleRideSelect={handleRideSelect} rides={ridesTomorrow} period={Period.tomorrow}/>
    </div>
    <div>
      <h1 className="text-3xl font-bold">Future's Rides {(ridesFuture && ridesFuture?.length > 0) && "- "+ ridesFuture[0].date}</h1>
      <Button onClick={()=> handleSearch(Period.future)}>Search</Button>
      <RideList handleRideSelect={handleRideSelect} rides={ridesFuture} period={Period.future}/>
    </div>
    <div>
      <h1 className="text-3xl font-bold">Yesterday's Rides {(ridesYesterday && ridesYesterday?.length > 0) && "- "+ ridesYesterday[0].date}</h1>
      <Button onClick={()=> handleSearch(Period.yesterday)}>Search</Button>
      <RideList handleRideSelect={handleRideSelect} rides={ridesYesterday} period={Period.yesterday}/>
    </div>
    <div>
      <h1 className="text-3xl font-bold">Past Rides {(ridesPast && ridesPast?.length > 0) && "- "+ ridesPast[0].date}</h1>
      <Button onClick={()=> handleSearch(Period.past)}>Search</Button>
      <RideList handleRideSelect={handleRideSelect} rides={ridesPast} period={Period.past}/>
    </div>
      <Modal isOpen={isOpen} onClose={closeModal} actions={[completeAction, acceptAction, rejectAction]} title= {"Ride Details"}>
        {ride && <RideItem ride={ride} setClientResponse={setClientResponse}/>}
      </Modal>

      
  </div>
  );
}

export default ClientBookings;