import axios from 'axios';

// TODO: Pass these from a properties file
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const enableInterceptors = process.env.REACT_APP_AXIOS_INTERCEPTORS;

// Create an Axios instance with the base URL
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor
if (enableInterceptors === 'true') {
    axiosInstance.interceptors.request.use(
        (config) => {
          // Log the headers before sending the request
          console.log('Request Headers:', config.headers);
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
}

// Now you can use axiosInstance for making requests throughout your app
export default axiosInstance;
