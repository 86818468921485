export const formattedTimestamp = (timestamp: number | string) => {
    if (typeof(timestamp) == "number" && !Number.isNaN(timestamp)) {
      return new Date(timestamp).toISOString();
    }
    return timestamp;
  }


 export const formattedTime = (hour?: number, minute?: number) => {
    return (
      (hour? hour.toString().padStart(2, "0") : "00") +
      ":" +
      (minute? minute.toString().padStart(2, "0"): "00")
    );
  };

  export const renderNumberOptions = (max: number, step?: number, startFrom?: number) => {
    const options: JSX.Element[] = [];
    step = step ? step : 1;
    startFrom = startFrom ? startFrom : 0;
    for (let i = startFrom; i <= max; i = i + step) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };