import { FC, useState, useEffect } from 'react';
import Select from 'react-select';
import "./SelectValue.css";

interface Option {
  value: number;
  label: string;
}

interface SelectValueProps {
  options?: Option[];
  onSelectChange: (id: number) => void;
  resetValue?: boolean; // New prop to trigger reset
  initOption?: Option;
  isDisabled?: boolean;
}

const SelectValue: FC<SelectValueProps> = (props: SelectValueProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined | null>(props.initOption? props.initOption : null);

  // Reset the selected option when resetValue changes
  useEffect(() => {

    setSelectedOption(props.initOption);
    if (props.resetValue) {
      setSelectedOption(null);
    }
  }, [props.resetValue, props.initOption]);

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      props.onSelectChange(selectedOption.value);
    }
  };

  return (
    <Select
    className='react-select-container'
      options={props.options}
      value={selectedOption}
      onChange={handleSelectChange}
      menuPortalTarget={document.body} 
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      isDisabled = {props.isDisabled}
    />
  );
};

export default SelectValue;
