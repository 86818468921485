import './Button.css';

type ButtonProps = {
  type?: 'submit' | 'warning' | 'confirm' | 'delete';
  onClick?: () => void;
  children: React.ReactNode;
  style?: any;
};

const Button: React.FC<ButtonProps> = ({ type = 'submit', onClick, children, style }) => {
  let buttonClass = 'button';

  // Determine the button style based on the "type" prop
  if (type === 'confirm') {
    buttonClass += ' confirm-button';
  } else if (type === 'delete') {
    buttonClass += ' delete-button';
  } else if (type === 'warning') {
    buttonClass += ' warning-button';
  }

  return (
    <button className={buttonClass} onClick={onClick} style={style}>
      {children}
    </button>
  );
};

export default Button;
