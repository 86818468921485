import React, { ChangeEventHandler, useEffect } from 'react';
import './DriverItem.css';
import { Driver } from '../../Types/Vehicle';

interface IMyProps {
  item: Driver,
  handleInputChange: ChangeEventHandler
}

const VehicleItem: React.FC<IMyProps> = (props:IMyProps) => {

  useEffect(() => {
      // eslint-disable-next-line
  }, []);

  return (
  <div>
    <div className="div-container">
      <div className="div-content">

      <div><p>Driver Id: <b>{props.item.id}</b> / <b>{props.item.firstName} {props.item.lastName}</b></p></div>

      <div>
        <label className="bookatrip">First name</label>
        <input type="text" name="firstName" value={props.item.firstName} onChange={props.handleInputChange} />
        <label className="bookatrip">Last name</label>
        <input type="text" name="lastName" value={props.item.lastName} onChange={props.handleInputChange} />
      </div>

      </div>
    </div>
  </div>
)};

export default VehicleItem;