import React from "react";
import "./VehicleDetail.css";
import { Vehicle } from "../../Types/Vehicle";

interface IMyProps {
  details: Vehicle,
  handleSelect: Function,
}

const VehicleDetail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleSelect(props.details);
  }

  return (
    <li key={props.details.id} onClick={handleSelect} className={`list-item`}>
      <div><p><b>{props.details.licencePlate}</b></p></div>
      <div><p><b>, {props.details.make}</b> <b>{props.details.model}</b></p></div>
      {props.details.driver&& <div>, Default Driver: {props.details.driver.id}. {props.details.driver.firstName} {props.details.driver.lastName}</div>}
    </li>);
};

export default VehicleDetail;