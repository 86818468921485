import React from "react";
import { login } from "@store/actions/accountActions";
import { Account } from "./types/Account";
import Button from "@common/Button";

interface IMyProps {
  accountDetails: Account;
}

const AccountDetail: React.FC<IMyProps> = (props: IMyProps) => {
  const handleLogin = () => {
    try {
      login(props.accountDetails.id);
      alert("Logged in with account id: " + props.accountDetails.id);
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div>
      <h2>{props.accountDetails.username}</h2>
      <p>{props.accountDetails.email}</p>
      <p>{props.accountDetails.firstName}</p>
      <p>{props.accountDetails.lastName}</p>
      <Button onClick={handleLogin}>Login (test)</Button>
    </div>
  );
};

export default AccountDetail;
