import {useState} from 'react'
import AccountList from './AccountList'
import { useAppDispatch, useAppSelector } from '@store/hooks' 
import {clearAccountsFromLocalStorage } from '@store/actions/accountActions'
import {fetchAccountsAsync, selectAccounts } from './accountSlice';
import Button from "@common/Button";

const AccountPage = () => {
    const accounts = useAppSelector(selectAccounts);
    const [ids, setIds] = useState('1,2,3');
    const dispatch = useAppDispatch();

    const handleSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      dispatch(fetchAccountsAsync(ids))
    };

    const handleClear = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      clearAccounts();
    };

    const clearAccounts = () => {
      dispatch(clearAccountsFromLocalStorage());
    };

return (
  <div>
    <h1>Accounts Page</h1>
    <p>Choose one of the accounts listed when submiting specific ids to imitate Login.</p>
    <form onSubmit={handleSubmit}>
    <label>
          Account Id list:
      <input
        type="text"
        value={ids}
        onChange={(event) => setIds(event.target.value)}
      />
      </label>
      <Button type="submit">Submit</Button>
    </form>
    <form onSubmit={handleClear}>
      <Button type="submit">Clear</Button>
    </form>
    {/* <p style={{color: "red"}}>Error: {JSON.stringify(accountsError)}</p> */}
    <AccountList accounts={accounts}/>
  </div>
)
}

export default AccountPage;