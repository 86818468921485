import {useState} from "react";
import { AxiosError } from "axios";
import { useAppDispatch } from "@/store/hooks";
import { clearAddresses } from "./addressSlice";
import AddressDetails from "./types/AddressDetails";
import Severity from "@common/types/Severity";
import { deleteAddress } from "./addressService";
import Button from "@common/Button";

interface IMyProps {
  address: AddressDetails,
  infoUpdateHandler: Function,
  messageHandler: Function
}

const DeleteAddress: React.FC<IMyProps> = (props:IMyProps) => {
  const address = props.address;
  const [confirm, setConfirm] = useState(false);
  const dispatch = useAppDispatch();

  const success = () => {
    localStorage.setItem("address", JSON.stringify(address));
    props.infoUpdateHandler(true);
    props.messageHandler({message: "Address details deleted.", severity: Severity.Info});
    setConfirm(false);
    dispatch(clearAddresses());
  }

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
        if (confirm) {
          try {
            if (address !== null && address.id) {
              await deleteAddress(address.id);
              success();
              return true;
            } else {
              props.messageHandler({message: "No details selected to be deleted.", severity: Severity.Error});
            }
          } catch (error: unknown) {
            if (error instanceof AxiosError) {
              props.messageHandler({message: error.message, severity: Severity.Error});
            }
          }
        } else {
          props.messageHandler({message: "You are about to delete these address details. This action cannot be reversed and you will need to contact OneAddress support to enable it again. Press 'Confirm' if you wish to continue", severity: Severity.Warning});
          setConfirm(true);
        }
  };

  const handleCancel = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setConfirm(false);
    props.messageHandler(null);
  };

  return (
      <div className="form-inline"><form onSubmit={handleSubmit} className="form-inline">
        <Button type="delete">{confirm ? "Confirm delete" : "Delete"}</Button>
      </form>
      {confirm && <form onSubmit={handleCancel} className="form-inline">
      <Button type="submit">Cancel</Button>
    </form>}
    </div>
  );
};

export default DeleteAddress;
