import React from "react";
import "./DriverDetail.css";
import { Driver } from "../../Types/Vehicle";

interface IMyProps {
  details: Driver,
  handleSelect: Function,
}

const DriverDetail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleSelect(props.details);
  }

  return (
    <li key={props.details.id} onClick={handleSelect} className={`list-item`}>
      <div><p><b>{props.details.id}</b></p></div>
      <div><p><b>, {props.details.firstName}</b> <b>{props.details.lastName}</b></p></div>
    </li>);
};

export default DriverDetail;