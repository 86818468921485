import React, { ChangeEvent, useState } from 'react';
import DriverDetail from './DriverDetail';
import './DriverList.css';
import { Driver } from '../../Types/Vehicle';
import Modal, { Action, ButtonType } from '@/components/Modal/Modal';
import { AxiosError } from 'axios';
import DriverItem from './DriverItem';
import { delWithAuthHeader, patchWithAuthHeader, postWithAuthHeader } from '@/utils/apiUtils';

const APPEND_URL = "/client/fleet/driver";

interface IMyProps {
  items: Driver[] | undefined,
  refresh: Function
}

const initDriver: Driver = {
  firstName: "",
  lastName: ""
}

const DriverList: React.FC<IMyProps> = (props:IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [driver, setDriver] = useState<Driver>(initDriver);

      const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setDriver(initDriver);
      setIsOpen(false);
    };

    const handleSelect = (item: Driver) => {
      console.log(item);
      setDriver(item);
      openModal();
    }

    const handleNew = (driver: Driver) => {
      setDriver(initDriver);
      openModal();
    }

    const handleDelete = async () => {
      if (driver && driver.id) {
        await delWithAuthHeader(`${APPEND_URL}/${driver.id}`)
        .then((response) => {
          if (response.data === true) {
            closeModal();
            props.refresh(false, driver.id);
          }
        })
        .catch((error: unknown)=>{
          if (error instanceof AxiosError) {
              // setMessage({ message: error.message, severity: Severity.Error });
            }
        }
        );
      }
    }
  
    const deleteAction: Action = {
      name: "Delete",
      method: handleDelete,
      buttonType: ButtonType.delete
    }

    const handleUpdate = async () => {
      console.log(driver);
      if (driver && driver.id) {
        await patchWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
            props.refresh(true);
        })
        .catch((error: unknown)=>{
          if (error instanceof AxiosError) {
              // setMessage({ message: error.message, severity: Severity.Error });
            }
        }
        );
      }
    }
  
    const updateAction: Action = {
      name: "Update",
      method: handleUpdate,
      buttonType: ButtonType.primary
    }

    const handleCreate = async () => {
      console.log(driver);
      if (driver && driver.firstName !== "" && driver.lastName !== "") {
        await postWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
            props.refresh(true);
            setDriver(initDriver);
        })
        .catch((error: unknown)=>{
          if (error instanceof AxiosError) {
              // setMessage({ message: error.message, severity: Severity.Error });
            }
        }
        );
      }
    }
  
    const createAction: Action = {
      name: "Create",
      method: handleCreate,
      buttonType: ButtonType.primary
    }

    const handleInputChange = (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      setDriver({
        ...driver,
        [e.target.name]: e.target.value,
      });
    };
  

    var actions: Action[] = [createAction, updateAction, deleteAction];

  return (
  <div>
    <button onClick={()=>handleNew(driver)}>ADD</button>
    <ul className='list-container'>
    {props.items?.map((item:Driver) => (
      <DriverDetail key={item.id} details={item} handleSelect= {handleSelect}/>
    ))}
    </ul>
    <Modal isOpen={isOpen} onClose={closeModal} actions={actions} title= {"Driver Details"}>
        {driver && <DriverItem item={driver} handleInputChange={handleInputChange}/>}
      </Modal>
  </div>
)};

export default DriverList;