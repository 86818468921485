import React from "react";
import "./TariffDetail.css";
import { Tariff } from "../../Types/Tariff";

interface IMyProps {
  details: Tariff,
  handleSelect: Function,
}

const TariffDetail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleSelect(props.details);
  }

  return (
    <li key={props.details.id} onClick={handleSelect} className={`list-item`}>
      {/* <div><p><b>{props.details.id}</b></p></div> */}
      <div><p><b>
        {/* ,  */}
        {props.details.fromLocation?.location_name} {props.details.fromLocation?.airportInd && '(Airport)'}</b> to <b>{props.details.toLocation?.location_name} {props.details.toLocation?.airportInd && '(Airport)'}</b></p></div>
      ({props.details.priceEuro}€)
    </li>);
};

export default TariffDetail;