// App.tsx
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "../Account/types/Message";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { getMessageContent } from "../Account/accountUtilities";
import Button from "../common/Button";
import SelectEnum from "../common/Select/SelectEnum";
import { formattedTime } from "@/utils/formatUtils";
import Passenger from "./Types/Passenger";
import { PaymentStatus } from "./Types/Status";
import { RideResponse } from "./Types/Ride";
import axiosInstance from "@/utils/axiosUtils";

const App: React.FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [passenger, setPassenger] = useState<Passenger>();
  const myParam = useLocation().search;
  const [uuid, setUuid] = useState<string|null>(new URLSearchParams(myParam).get("uuid"));
  const [uuidInProgress, setUuidInProgress] = useState<string|null>(new URLSearchParams(myParam).get("uuid"));
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus|undefined>(PaymentStatus.UNPAID);
  const [rideResponse, setRideResponse] = useState<RideResponse>();
  const [cashDeposit, setCashDeposit] = useState<number>();

  useEffect(() => {
    // Fetch locations
    console.log(uuid);
    if (uuid) {
      axiosInstance.patch(`/passengers/confirm/${uuid}`, {})
      .then((response) => {
        setPassenger(response.data);
        console.log(response.data);
        setPaymentStatus(response.data.paymentStatus);
        console.log(response.data.ride.date);
        setMessage({ message: "Your details have been confirmed. Please proceed to selecting payment method.", severity: Severity.Info });
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  const checkShareARideStatus = () => {
    if (passenger?.ride.id) {
      axiosInstance.get(`/rides/${passenger.ride.id}`)
      .then((response) => {
        setRideResponse(response.data);
        console.log(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      });
    }
  }

  const handleUuidChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUuidInProgress(e.target.value);
  };

  const handleUuidSubmit = () => {
    setUuid(uuidInProgress);
  }

  const handlePaymentStatusSubmit = () => {
    if (paymentStatus !== passenger?.paymentStatus) {
      axiosInstance.get(`/payments/passenger/${uuid}/${paymentStatus}`, {})
      .then((response) => {
        setPassenger(response.data);
        setMessage({ message: "Payment Status changed to: "+ paymentStatus, severity: Severity.Info });
        setPaymentStatus(response.data.paymentStatus);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      });
    } else {
      setMessage({ message: "Payment Status has not been changed. No action taken", severity: Severity.Warning });
    }
    
  }

  const handlePaymentConfirmationSubmit = () => {
    if (passenger?.paymentStatus !== PaymentStatus.BANK_TRANSFER_CONFIRMED && passenger?.paymentStatus !== PaymentStatus.CASH_TRANSFER_CONFIRMED) {
      axiosInstance.get(`/payments/passenger/confirm/${uuid}`, {})
      .then((response) => {
        setPassenger(response.data);
        setMessage({ message: "Passenger confirmed and ride created", severity: Severity.Info });
        setPaymentStatus(response.data.paymentStatus);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      });
    } else {
      setMessage({ message: "Payment has already been confirmed ("+passenger?.paymentStatus+")", severity: Severity.Info });
    }
  }

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const handleSelectChange =
  (enumObject: Record<string, string>, name: keyof Passenger) =>
  (selectedOption: any) => {
    setPaymentStatus(selectedOption);

    if (selectedOption === PaymentStatus.CASH_TRANSFER_UNCONFIRMED) {
      axiosInstance.get(`/payments/passenger/cashDeposit/${uuid}`)
      .then((response) => {
        setCashDeposit(response.data);
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.message, severity: Severity.Error });
          }
      });
    } else {
      setCashDeposit(undefined);
    }
  };

  return (
    <div>
      {messageContent}
      <h1>Manage Your Booking</h1>
      {uuid ? 
        <div><p>Unique Reference: {uuid}</p>
        

        <p><b>*Simulation of payment process</b></p>
        <p><b>If you are the originator, then you need to pay either full amount as bank transfer or the minimum amount as deposit</b></p>
        <p><b>If you are joining an existing ride, then you need to pay your current share or the minimum amount as deposit</b></p>
          <label>Payment Status: </label>
          <SelectEnum
              viewOnly={false}
              selectedValue={paymentStatus}
              enumObject={PaymentStatus}
              onSelect={handleSelectChange(PaymentStatus, "paymentStatus")}
            />
            {cashDeposit && <div>Cash Deposit Amount: {cashDeposit} € (+final amount in cash)</div>}
            <Button type='submit' onClick={handlePaymentStatusSubmit}>Submit</Button>
        </div>
        : <div>
          
          <form onSubmit={handleUuidSubmit}>
          <p>Please provide your unique reference to continue</p>
          <p><b>*Simulation of email confirmation</b></p>
          <label>Booking reference <b>(For testing only):</b></label>
          <input
            type="text"
            name="uuidInProgress"
            placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxx"
            onChange={handleUuidChange}
          />
          <Button type='submit'>Confirm</Button>
          </form>
        </div>


    }
    {uuid && (passenger?.paymentStatus !== PaymentStatus.CASH_TRANSFER_CONFIRMED && passenger?.paymentStatus !== PaymentStatus.BANK_TRANSFER_CONFIRMED) &&
    <div>
      <p><b>*Simulation of payment confirmation process  (The following action will be performed by the Operator). At this point, a vehicle and a driver will also be assigned</b></p>
            <label>Payment Confirmation: </label>
            <Button type='submit' onClick={handlePaymentConfirmationSubmit}>Confirm and Create Ride</Button>
        </div>}

        {passenger && <div><h1>Passenger Details</h1>
        <div><label>Email: </label> <span>{passenger.email}</span></div>
        <div><label>Number of People: </label> <span>{passenger.people}</span></div>
        <div><label>Number of Luggage: </label> <span>{passenger.luggage}</span></div>
        <div><label>Payment Status: </label> <b><span>{passenger.paymentStatus === PaymentStatus.CASH_TRANSFER_CONFIRMED || passenger.paymentStatus === PaymentStatus.BANK_TRANSFER_CONFIRMED ? "Paid" : "In Progress"}</span></b></div>
        <div><label>Originator: </label> <input type="checkbox" checked={passenger.original} readOnly/></div>
        <div>
          <h1>Ride details</h1>
          <div><label>Date:</label> <span>{passenger.ride?.date}</span></div>
          <div><label>Time:</label> <span>{formattedTime(passenger.ride?.hour, passenger.ride?.minute)}</span></div>
          <div><label>Pick-up location:</label> <b><span>{passenger.ride?.destinationFrom?.location_name}</span></b></div>
          <div><label>Destination:</label> <b><span>{passenger.ride?.destinationTo?.location_name}</span></b></div>
          {(passenger.ride?.destinationFrom?.airportInd || passenger.ride?.destinationTo?.airportInd) && 
          <div>
            <div><label>Flight No: </label> <b><span>{passenger.ride?.flightNo}</span></b></div>
            </div>}
            <div><label>Status:</label> <b><span>{passenger.ride?.status}</span></b></div>
        </div>

        <div>
          <h2>Tariff details</h2>
          <div><label>Original Price:</label> <span>{passenger.ride?.tariff.priceEuro} €</span></div>
        </div>

        <div>
          <h1>Your Transfer Company</h1>
          <div><label>Company Name:</label> <span>{passenger.ride?.client.companyName}</span></div>
          <div><label>Country:</label> <span>{passenger.ride?.client.country}</span></div>
          <div><label>Email:</label> <span>{passenger.ride?.client.email}</span></div>
          <div><label>Address Line 1:</label> <span>{passenger.ride?.client.addressLine1}</span></div>
          <div><label>Address Line 2:</label> <span>{passenger.ride?.client.addressLine2}</span></div>
          <div><label>Postcode:</label> <span>{passenger.ride?.client.postcode}</span></div>
          <h2>Payment Details</h2>
          <ul>
            {passenger.ride?.client.paymentDetails?.map((paymentDetails) => (
              <li key={paymentDetails.id}>
                {paymentDetails.paymentMethod && <p>{paymentDetails.paymentMethod}</p>}
                {paymentDetails.recipientName && <p>Recepient: {paymentDetails.recipientName}</p>}
                {paymentDetails.bankName && <p>Bank: {paymentDetails.bankName}</p>}
                {paymentDetails.iban && <p>IBAN: {paymentDetails.iban}</p>}
                {paymentDetails.swiftCode && <p>Swift Code: {paymentDetails.swiftCode}</p>}
                {paymentDetails.accountNumber && <p>Account No: {paymentDetails.accountNumber}</p>}
                {paymentDetails.tag && <p>Tag: {paymentDetails.tag}</p>}
                {paymentDetails.phoneNumber && <p>Phone Number: {paymentDetails.phoneNumber}</p>}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h1>Your Driver</h1>
          {passenger.ride?.driver ? <div><label>Driver name:</label> <span>{passenger.ride.driver.firstName} {passenger.ride.driver.lastName}</span></div>
          : <div>Pending...</div>}
          
        </div>

        <div>
          <h1>Vehicle details</h1>
          {passenger.ride?.vehicle ? <div><div><label>Vehicle Type:</label> <span>{passenger.ride?.vehicle.vehicleType}</span></div>
          <div><label>Licence Plate:</label> <span>{passenger.ride?.vehicle.licencePlate}</span></div>
          <div><label>Make:</label> <span>{passenger.ride?.vehicle.make}</span></div>
          <div><label>Model:</label> <span>{passenger.ride?.vehicle.model}</span></div>
          <div><label>Maximum People:</label> <span>{passenger.ride?.vehicle.maxPeople}</span></div>
          <div><label>Maximum Luggage:</label> <span>{passenger.ride?.vehicle.maxLuggage}</span></div></div>
          : <div>Pending...</div>}

        </div>

        
        </div>
        }
        
        {uuid && <div><h1 style={{display: "inline-block"}}>Share-a-ride details</h1><Button type='submit' onClick={checkShareARideStatus}>Check</Button></div>}
        {rideResponse && <div>
          <div><label>Individual Bookings:</label> <span>{rideResponse.bookingCount}</span></div>
          <div><label>Unconfirmed Bookings:</label> <span>{rideResponse.bookingCountUnconfirmed}</span></div>
          <div><label>Passengers:</label> <span>{rideResponse.passengerCount}/{rideResponse.passengerTotal}</span></div>
          <div><label>Unconfirmed Passengers:</label> <span>{rideResponse.passengerCountUnconfirmed}/{rideResponse.passengerTotal}</span></div>
          <div><label>Luggage:</label> <span>{rideResponse.luggageCount}/{rideResponse.luggageTotal}</span></div>
          <div><label>Unconfirmed Luggage:</label> <span>{rideResponse.luggageCountUnconfirmed}/{rideResponse.luggageTotal}</span></div>
          <div><label>Original Price:</label> <span>{rideResponse.originalPrice} €</span></div>
          <div><label>Your Current Price:</label> <span>{rideResponse.currentPrice} €</span></div>
          <div><label>Next Best Price:</label> <span>{rideResponse.yourSharePrice} €</span></div>
          <div><label>Absolute Best Price:</label> <span>{rideResponse.bestPrice} €</span></div>
          </div>}
    </div>
  );
};

export default App;
