import React from 'react';
import AddressDetail from './AddressDetail';
import AddressDetails from './types/AddressDetails';
import './AddressList.css';

interface IMyProps {
  addresses: AddressDetails[],
  handleAddressSelect: Function
}

const AddressList: React.FC<IMyProps> = (props:IMyProps) => {
  return (
  <div>
    <ul className='list-container'>
    {props.addresses.map((address:AddressDetails) => (
      <AddressDetail key={address.id} addressDetails={address} handleAddressSelect= {props.handleAddressSelect}/>
    ))}
    </ul>
  </div>
)};

export default AddressList;