enum DetailsCategory {
    HOME = 'Home',
    WORK = 'Work',
    BUSINESS = 'Business',
    SCHOOL = 'School',
    PHONE_IPHONE = 'iPhone',
    PHONE_ANDROID = 'Android',
    PHONE_MOBILE = 'Mobile',
    OTHER = 'Other',
  }

  export default DetailsCategory;