import { Dispatch } from 'redux';
import {clearAddresses, clearContacts} from '@components/Address/addressSlice';

export const clearAddressesFromLocalStorage = () => async (dispatch:Dispatch) => {
  localStorage.removeItem("addresses");
  localStorage.removeItem("addressesLastUpdateTimestamp");
    dispatch(clearAddresses());
  };

export const clearContactsFromLocalStorage = () => async (dispatch:Dispatch) => {
    localStorage.removeItem("contacts");
    localStorage.removeItem("contactsLastUpdateTimestamp");
    dispatch(clearContacts());
  };