import React from "react";
import "./RideDetail.css";
import { Period, Ride } from "../Types/Ride";
import { formattedTime } from "@/utils/formatUtils";

interface IMyProps {
  rideDetails: Ride,
  handleRideSelect: Function,
  period?: Period
}

const RideDetail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleRideSelect(props.rideDetails, props.period);
  }

  return (
    <li key={props.rideDetails.id} onClick={handleSelect} className={`list-item ${props.rideDetails.status}`}>
      <div><p><b>{props.rideDetails.date}, {formattedTime(props.rideDetails.hour, props.rideDetails.minute)}</b></p></div>
      <div><p><b>, {props.rideDetails.destinationFrom.location_name}</b> to <b>{props.rideDetails.destinationTo.location_name}</b></p></div>
      <div>, {props.rideDetails.tariff.priceEuro}€</div>
      <div>, {props.rideDetails.status}</div>
    </li>);
};

export default RideDetail;