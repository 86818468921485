import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { getAccount, getAccounts } from "./accountService";
import { Account } from "./types/Account";
import { register } from "@/utils/authUtils";

const initialState = {
  loading: false,
  accounts:
    localStorage.accounts != null ? JSON.parse(localStorage.accounts) : [],
  account: null,
  error: "",
};

export const fetchAccountsAsync = createAsyncThunk(
  "accounts/fetch",
  async (ids: string) => {
    console.log("Inside accountReducer.fetchAccountsAsync");
    const response = await fetchAccounts(ids);
    console.log(response);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const fetchAccountAsync = createAsyncThunk(
  "account/fetch",
  async () => {
    const response = await getAccount();
    return response;
  }
);

export const createAccountAsync = createAsyncThunk(
  "accounts/create",
  async (newAccount: Account) => {
    try {
      const response = await register(newAccount);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearAccounts: (state) => {
      state.accounts = [];
      state.account = null;
      state.loading = false;
      state.error = "";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
    // Handle fetchAccountsAsync actions
      .addCase(fetchAccountsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountsAsync.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.accounts = action.payload;
      })
      .addCase(fetchAccountsAsync.rejected, (state) => {
        state.error = "failed";
      })
      // Handle fetchAccountAsync actions
      .addCase(fetchAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.account = action.payload;
      })
      .addCase(fetchAccountAsync.rejected, (state) => {
        state.error = "failed";
      })
      // Handle createAccountAsync actions
      .addCase(createAccountAsync.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createAccountAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAccountAsync.rejected, (state) => {
        state.loading = false;
        state.error = "failed to create the account";
      });
  },
});

export const fetchAccounts = async (ids:string) => {
    const accounts = await getAccounts(ids);
    localStorage.setItem("accounts", JSON.stringify(accounts));
    localStorage.setItem("account", JSON.stringify(accounts[0]));
    return accounts;
};

export const selectAccounts = (state: RootState) => state.accounts.accounts;
export const selectAccount = (state: RootState) => state.accounts.account;
export const selectLoadingState = (state: RootState) => state.accounts.loading;
export const selectErrorState = (state: RootState) => state.accounts.error;
export const {clearAccounts } = accountsSlice.actions;

export default accountsSlice.reducer;
