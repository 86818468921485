import { Message } from "./types/Message";
import Severity from "../common/types/Severity";

export const getMessageContent = (message: Message) => {
  switch (message.severity) {
    case Severity.Error:
      return (
        <p style={{ color: "red", backgroundColor: "black" }}>
          <b>ERROR: </b>{message.code && <b>{message.code}:</b>} {message.message}
        </p>
      );
    case Severity.Warning:
      return (
        <p style={{ color: "orange", backgroundColor: "black"}}>
          <b>WARNING: </b>{message.code && <b>{message.code}:</b>} {message.message}
        </p>
      );
    default:
      return (
        <p style={{ color: "green", backgroundColor: "black"}}>
          <b>INFO: </b>{message.code && <b>{message.code}:</b>} {message.message}
        </p>
      );
  }
};
