import { useState } from "react";
import "./Login.css";
// import PropTypes from "prop-types";
import { isAuthenticated, login, logout } from "@/utils/authUtils";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { getMessageContent } from "../Account/accountUtilities";
import { Message } from "../Account/types/Message";
import Register from "../Register/Register";
import {useNavigate} from 'react-router-dom';
import Button from "@common/Button";

interface IMyProps {
  setAuth?: Function;
}

const Login: React.FC<IMyProps> = (
  props: IMyProps
  ) => {
  const [username, setUserName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [message, setMessage] = useState<Message>({});
    const navigate = useNavigate();

  const handleLogout = async (e: any) => {
    try {
      e.preventDefault();
      await logout(false);
      if (props.setAuth !== undefined) props.setAuth(false);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  const handleLogin = async (e: any) => {
    try {
      e.preventDefault();
      await login(username, password);
      if (props.setAuth !== undefined) props.setAuth(true);
      setMessage({});
      setRedirectLogin(true);
    } catch (error: unknown) {
      console.log("ERROR: " + error);
      if (error instanceof AxiosError) {
        setMessage({ message: error.message, severity: Severity.Error });
      }
    }
  };

  let messageContent = null;

  if (message !== null && (message.code || message.message || message.severity)) {
    messageContent = getMessageContent(message);
  }

  if (redirectLogin) {
    navigate('/dashboard');
  } else if (isAuthenticated()) {
    return (
      <div className="login-wrapper">
        <h1>Are you sure you want to logout?</h1>
        <form onSubmit={handleLogout}>
          <div>
            <Button type="submit">Logout</Button>
          </div>
        </form>
        {messageContent}
      </div>
    );
  }

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleLogin}>
        <label>
          <p>Username/Email</p>
          <input type="text" onChange={(e) => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <div>
          <Button type="submit">Login</Button>
        </div>
      </form>
      {messageContent}
      <Register/>
    </div>
  );
};

export default Login;

// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };
