import {useState} from "react";
import {deleteAccount} from "@store/actions/accountActions";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { Account } from "./types/Account";
import { useAppDispatch } from "@/store/hooks";
import { clearAccounts } from "./accountSlice";
import Button from "@common/Button";

interface IMyProps {
  account: Account,
  infoUpdateHandler: Function,
  messageHandler: Function
}

const DeleteAccount: React.FC<IMyProps> = (props:IMyProps) => {
  const account = props.account;
  const [confirm, setConfirm] = useState(false);
  const dispatch = useAppDispatch();

  const success = () => {
    localStorage.setItem("account", JSON.stringify(account));
    props.infoUpdateHandler(true);
    props.messageHandler({message: "Account deleted.", severity: Severity.Info});
    setConfirm(false);
    dispatch(clearAccounts());
  }

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
        if (confirm) {
          try {
            await deleteAccount();
            success();
            return true;
          } catch (error: unknown) {
            if (error instanceof AxiosError) {
              props.messageHandler({message: error.message, severity: Severity.Error});
            }
          }
        } else {
          props.messageHandler({message: "You are about to delete your account. This action cannot be reversed and you will need to contact OneAddress support to enable it again. Press 'Confirm' if you wish to continue", severity: Severity.Warning});
          setConfirm(true);
        }
  };

  return (
    <div>
      <h2>Manage Account</h2>
      <form onSubmit={handleSubmit}>
        <Button type="delete">{confirm ? "Confirm delete" : "Delete"}</Button>
      </form>
    </div>
  );
};

export default DeleteAccount;
