import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAddresses } from './addressService';
import { RootState } from '@store/store';

const PERSONAL = "PERSONAL";
const CONTACT = "CONTACT";

  const initialState = {
    loading: false,
    addresses: localStorage.addresses != null ? JSON.parse(localStorage.addresses) : [],
    addressesLastUpdateTimestamp: localStorage.addressesLastUpdateTimestamp != null ? Date.parse(localStorage.addressesLastUpdateTimestamp.toString()) : "",
    contacts: localStorage.contacts != null ? JSON.parse(localStorage.contacts) : [],
    contactsLastUpdateTimestamp: localStorage.contactsLastUpdateTimestamp != null ? Date.parse(localStorage.contactsLastUpdateTimestamp.toString()) : "",
    error: "",
  };

  export const fetchAddressesAsync = createAsyncThunk(
    "addresses/fetch",
    async (scope: string | undefined) => {
      const response = await fetchAddresses(scope);
      console.log(response);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  );
  
  export const addressesSlice = createSlice({
    name: "addresses",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      clearAddresses: (state) => {
        state.addresses = [];
        state.loading = false;
        state.error = "";
        state.addressesLastUpdateTimestamp = "";
      },
      clearContacts: (state) => {
        state.contacts = [];
        state.loading = false;
        state.error = "";
        state.contactsLastUpdateTimestamp = "";
      },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(fetchAddressesAsync.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchAddressesAsync.fulfilled, (state, action) => {
          let timestamp = new Date().getTime();
          if (action.meta.arg === "PERSONAL") {
            state.addresses = action.payload;
            state.addressesLastUpdateTimestamp = timestamp;
            localStorage.setItem("addressesLastUpdateTimestamp", timestamp.toString());
          } else if (action.meta.arg === "CONTACT") {
            state.contacts = action.payload;
            state.contactsLastUpdateTimestamp = timestamp;
            localStorage.setItem("contactsLastUpdateTimestamp", timestamp.toString());
          }
          state.loading = false;
        })
        .addCase(fetchAddressesAsync.rejected, (state) => {
          state.error = "failed";
        });
    },
  });
  
  export const fetchAddresses = async (scope:string|undefined) => {
      const addresses = await getAddresses(scope);
      if (scope === PERSONAL) {
        localStorage.setItem("addresses", JSON.stringify(addresses));
      } else if (scope === CONTACT) {
        localStorage.setItem("contacts", JSON.stringify(addresses));
      }
      return addresses;
  };
  
  export const selectAddresses = (state: RootState) => state.addresses.addresses;
  export const selectAddressesTimestamp = (state: RootState) => state.addresses.addressesLastUpdateTimestamp;
  export const selectContactsTimestamp = (state: RootState) => state.addresses.contactsLastUpdateTimestamp;
  export const selectContacts = (state: RootState) => state.addresses.contacts;
  export const selectLoadingState = (state: RootState) => state.addresses.loading;
  export const selectErrorState = (state: RootState) => state.addresses.error;
  export const {clearAddresses, clearContacts} = addressesSlice.actions;
  
  export default addressesSlice.reducer;