import React from "react";
import AddressDetails from "./types/AddressDetails";
import DetailsStatus from "./types/DetailsStatus";
import "./AddressDetail.css";
interface IMyProps {
  addressDetails: AddressDetails,
  handleAddressSelect: Function
}

const AddressDetail: React.FC<IMyProps> = (props: IMyProps) => {

  const handleSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    props.handleAddressSelect(props.addressDetails);
  }

  var indexOf = Object.keys(DetailsStatus).indexOf(props.addressDetails.status);
  var status = Object.values(DetailsStatus)[indexOf];

  return (
    <li onClick={handleSelect} className="list-item">
      <img src={require('./logo192.png')}  alt="Profile"></img>
      <p><b>{props.addressDetails.name}</b> - {status}</p>
    </li>);
};

export default AddressDetail;